import React, { useEffect, useState } from 'react';
import {
  Autocomplete as MuiAutoComplete,
  InputAdornment,
  Typography,
} from '@mui/material';
import TextField, { ITextFieldProps } from '../../atoms/TextField';
import { makeStyles } from '@mui/styles';
import theme from '../../../core-utils/theme';
import useLocationAutocomplete from './index.hook';
import PolicyService from '../../../api-service/policy-service/PolicyService';
import policyMessages from '@src/core-utils/messages/policyMessages';
import { Policy_Blocks } from '@organisms/PolicyBuilder';

export interface IDropdownOptionProps {
  id: number | string;
  name: string;
  metadata?: any;
  displayName?: string;
}

export interface IDropdownProps extends ITextFieldProps {
  inputValue?: string;
  value: any;
  label?: string;
  options: Array<IDropdownOptionProps>;
  handleDropdownChange?: (event: any, value: any) => void;
  handleInputChange?: (e: any) => void;
  disableClearable?: boolean;
  disableOptions?: any;
  type?: string;
  policyValueErrorMessage?: string;
  cyId?: string;
}

const useStyles = makeStyles(() => ({
  paper: {
    border: '1px solid' + theme.palette.grey[300],
    borderRadius: '3px',
    boxShadow: theme.shadows[7],
    width: theme.spacing(81),
    color: theme.palette.text.secondary,
  },
}));


const PoliyBuilderValueDropdown = ({
  label,
  value,
  options,
  disableClearable = true,
  disableOptions,
  handleDropdownChange,
  handleInputChange,
  type,
  policyValueErrorMessage,
  cyId,
  ...rest
}: IDropdownProps) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [inputValue, setInputValue] = useState(
    value?.displayName
      ? value.displayName
      : value?.name
      ? value.name
      : value
      ? value
      : '',
  );
  const classes = useStyles();
  const { ddoptions, handleChange } = useLocationAutocomplete();
  const [appOptions, setAppOptions] = useState<IDropdownOptionProps[]>([]);
  const [contentInspectionOptions, setContentInspectionOptions] = useState<
    IDropdownOptionProps[]
  >([]);
  const [isFreeText, setFreeText] = useState<boolean>(
    value?.id > 0 ? false : true,
  );

  const [dropDownValueSelect, setDropDownValueSelect] = useState(false);
  const [dropdownClose, setDropDownClose] = useState(false);

  const handleDropDownStateChange = () => {
    if (!inputValue) {
      setIsDropDownOpen(false);
    } else {
      if (
        options?.some((option) => {
          return option.name === inputValue;
        })
      ) {
        setIsDropDownOpen(false);
      } else {
        setIsDropDownOpen(true);
      }
    }
  };

  useEffect(() => {
    handleDropDownStateChange();
  }, [inputValue]);

  const loadAppOptions = async () => {
    const apps = await PolicyService.getAppsList();
    setAppOptions(apps);
  };

  const loadContentInspectionOptions = async () => {
    const options = await PolicyService.getContentInspectionValuesList();
    setContentInspectionOptions(
      options?.infoTypes.map((option: any, index: number) => {
        const data = {
          id: index,
          name: option.name,
          displayName: option.displayName,
          metadata: {
            description: option.description,
            supportedBy: option.supportedBy,
          },
        };
        return data;
      }),
    );
  };

  const loadDeviceAccessOptions = async () => {
    const deviceAccessList = await PolicyService.getDeviceAccessList();
    setAppOptions((deviceAccessList?.deviceAccess && deviceAccessList?.deviceAccess.length > 0)  
      ? (deviceAccessList?.deviceAccess?.map((deviceItemData: any, index: number)=> ({
          id: index,
          name: deviceItemData?.name,
          metadata: deviceItemData?.description,
          displayName: deviceItemData?.displayName
        }))) 
      : []);
  }

  useEffect(() => {
    type === Policy_Blocks.APP && loadAppOptions();
    type === Policy_Blocks.CONTENT && loadContentInspectionOptions();
    type === Policy_Blocks.ACCESS && loadDeviceAccessOptions();
  }, []);

  return (
    <div className='relative'>
      <MuiAutoComplete
        disablePortal
        //open={isDropDownOpen}
        // onOpen={() => {
        //   if (inputValue.length > 0) {
        //     setIsDropDownOpen(true);
        //   }
        // }}
        onClose={() => {
          setDropDownClose(true);
        }}
        disableClearable={disableClearable}
        clearOnBlur={Policy_Blocks.CONTENT ? false : true}
        options={
          type === 'location'
            ? ddoptions
            : type === 'app' || type === 'access'
              ? appOptions
              : type === 'content'
                ? contentInspectionOptions
                : options
        }
        getOptionLabel={(option: any) =>
          (type === Policy_Blocks.CONTENT || type === Policy_Blocks.ACCESS) ? option?.displayName : option?.name
        }
        onInputChange={(e, value, reason) => {
          setInputValue(value);
          handleInputChange && handleInputChange(e);
          setFreeText(true);
        }}
        inputValue={inputValue}
        onChange={(e, value) => {
          handleDropdownChange && handleDropdownChange(e, value);
          setFreeText(false);
          setDropDownValueSelect(true);
        }}
        // freeSolo={true}
        autoHighlight={false}
        // popupIcon={''}
        classes={{
          paper: classes.paper,
        }}
        renderInput={({ inputProps, ...restParams }) => (
          <TextField
            cy-data-id={cyId}
            required
            inputProps={inputProps}
            handleChange={
              type === 'location'
                ? (event) => handleChange(event.target.value)
                : () => { }
            }
            autoComplete="on"
            {...restParams}
            {...rest}
            label={label}
            error={
              policyValueErrorMessage !== undefined &&
              policyValueErrorMessage !== ''
            }
            helperText={policyValueErrorMessage}
            sx={{
              width: 173,
            }}
          />
        )}
      />
      {(type !== Policy_Blocks.CONTENT && isFreeText && dropdownClose && !dropDownValueSelect) &&
        <p className='absolute text-xs text-red-500 my-0'> {policyMessages?.DROPDOWN_ERROR_MESSAGE} </p>}
    </div>
  );
};

export default PoliyBuilderValueDropdown;
