import React from 'react';
import { Autocomplete as MuiAutoComplete } from '@mui/material';
import TextField, { ITextFieldProps } from '../../atoms/TextField';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import { makeStyles } from '@mui/styles';

export interface IDropdownOptionProps {
  id: number | string;
  name: string;
  code?: string;
}

export interface IDropdownProps extends ITextFieldProps {
  inputValue?: string;
  value: any;
  label?: string;
  options: Array<IDropdownOptionProps>;
  handleDropdownChange?: (event: any, value: any) => void;
  handleInputChange?: (event: any, value: any) => void;
  disableClearable?: boolean;
  disableOptions?: any;
  cyId?: string;
}

const useStyles = makeStyles({
  paper: {
    border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
    boxShadow: theme.shadows[7],
  },
});

const Dropdown = ({
  label,
  inputValue,
  value,
  options,
  disableClearable = true,
  disableOptions,
  handleDropdownChange,
  handleInputChange,
  disabled = false,
  cyId = '',
  ...rest
}: IDropdownProps) => {
  const classes = useStyles();
  return (
    <MuiAutoComplete
      disabled={disabled}
      disablePortal
      disableClearable={disableClearable}
      options={options}
      getOptionDisabled={disableOptions}
      getOptionLabel={(option: any) => option?.name}
      onInputChange={handleInputChange}
      value={value}
      inputValue={value ? value.name : ''}
      filterOptions={(currentOptions, _state) =>
        currentOptions.filter((option) => {
          return option.id !== value?.id;
        })
      }
      onChange={handleDropdownChange}
      autoHighlight={false}
      classes={{ paper: classes.paper }}
      renderInput={({ inputProps, ...restParams }) => (
        <TextField
          required
          inputProps={inputProps}
          autoComplete="on"
          {...restParams}
          {...rest}
          label={label}
          sx={rest.sx}
          cy-data-id={`option-${cyId}`}
        />
      )}
    />
  );
};

export default Dropdown;
